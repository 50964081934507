import { IConfigurationFormProduct } from '../../assets/ts/types';

const EMPTY_PRODUCT: IConfigurationFormProduct = {
    id: 0,
    name: '',
    nameHtml: null,
    price: 0,
    articul: 0,
    recommended: false,
    onlyForPresale: false,
    attributes: null,
    tag: null,
    showPimParams: false,
};

const SEARCH_SLOT_PRODUCTS_COLUMNS: { name: string; title: string }[] = [
    {
        name: 'articul',
        title: 'Артикул',
    },
    {
        name: 'name',
        title: 'Наименование',
    },
    {
        name: 'amountInStock',
        title: 'Остаток',
    },
    {
        name: 'price',
        title: 'Цена, $',
    },
    {
        name: 'recommend',
        title: '',
    },
];

const ONLY_FOR_PRESALE_TAG_TEXT = 'только для пресейла';

export { EMPTY_PRODUCT, SEARCH_SLOT_PRODUCTS_COLUMNS, ONLY_FOR_PRESALE_TAG_TEXT };
