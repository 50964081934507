import { IConfigurationHistory, IDataConfigurationHistoryResponse } from '../../types';
import convertToPascalCase from '../convert-to-pascal-case';

const convertConfigurationHistory = ({ field_translate, history }: IDataConfigurationHistoryResponse) => {
    const listFields = Object.entries(field_translate).map(([name, title]) => {
        return { name: convertToPascalCase(name), title };
    });
    const listConfigurationsHistory: IConfigurationHistory[] = history.map(conf => {
        const { created_at, old_pricelist_id, old_name, old_count, pricelist_id, ...rest } = conf;
        const date = new Date(conf.created_at);

        return {
            createdAt: `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
            oldPricelistId: conf.old_pricelist_id,
            oldName: conf.old_name,
            oldCount: conf.old_count,
            pricelistId: conf.pricelist_id,
            ...rest,
        };
    });

    return {
        fields: listFields,
        history: listConfigurationsHistory,
    };
};

export default convertConfigurationHistory;
