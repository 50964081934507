import cx from 'classnames';
import React, { useCallback, useContext } from 'react';
import { IConfigurationFormProduct } from '../../assets/ts/types';
import ModalContext from '../../store/context/ModalContext';
import Button from '../button';

interface IProps {
    item: IConfigurationFormProduct;
    className?: string;
}

const SingleValueContent = ({ item, className }: IProps) => {
    const { setData: setModal } = useContext(ModalContext);
    const { name: optionName, nameHtml: optionNameHtml, price: optionPrice, noPrice, articul, showPimParams, getPimInfoModalOptions } = item;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';
    const contentClassName = className ?? 'select__option';

    const getPimInfo = useCallback(() => {
        if (!getPimInfoModalOptions) return;
        setModal(getPimInfoModalOptions(item));
    }, [item]);

    return (
        <>
            <div className={cx([`select__option-content-item ${contentClassName}-name`, { '_no-price': noPrice }])}>
                {optionNameHtml && !optionNameHtml.includes('<script') ? (
                    <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                ) : (
                    <span>{optionName}</span>
                )}
            </div>
            {articul && showPimParams ? (
                <Button
                    styleType="icon-only"
                    className="select__option-content-item select__option-btn _pim-info"
                    onMouseDown={e => {
                        e.stopPropagation();
                        getPimInfo();
                    }}
                    tip="Подробнее о комплектующей"
                >
                    <div className="icon" />
                </Button>
            ) : (
                ''
            )}
            {!noPrice && (
                <div className={`select__option-content-item ${contentClassName}-price-wrapper`}>
                    &mdash;
                    <span className={cx([`${contentClassName}-price`, { _error: !optionPrice }])}>
                        {price} {!!optionPrice && ' $'}
                    </span>
                </div>
            )}
        </>
    );
};

export default SingleValueContent;
