import styled from '@emotion/styled';
import cx from 'classnames';
import React, { memo, useCallback, useContext } from 'react';
import { OptionProps } from 'react-select';
import { IConfigurationFormProduct, ISelectOption } from '../../assets/ts/types';
import ConfiguratorFormTags from '../../modules/configurator-form/ConfiguratorFormTags';
import ModalContext from '../../store/context/ModalContext';
import Button from '../button';

const COLOR_FOCUSED = 'rgba(0, 148, 130, 0.15)';
const COLOR_SELECTED = 'rgba(0, 148, 130, 0.25)';

const Wrapper = styled.div<{ isFocused: boolean; isSelected: boolean }>`
    padding: 9px 11px 12px;
    display: flex;
    gap: 21px;
    background: ${props => {
        let bg = 'transparent';
        if (props.isFocused) bg = COLOR_FOCUSED;
        if (props.isSelected) bg = COLOR_SELECTED;
        return bg;
    }};
    cursor: ${props => (!props.isSelected && props.isFocused ? 'pointer' : 'auto')};
`;

const Option = (props: OptionProps<IConfigurationFormProduct & ISelectOption>) => {
    const { setData: setModal } = useContext(ModalContext);
    const { data: option, isSelected, isFocused, innerRef, innerProps, label, selectProps } = props;
    const {
        name: optionName,
        nameHtml: optionNameHtml,
        price: optionPrice,
        noPrice,
        showAmountInStock,
        articul,
        showPimParams,
        getPimInfoModalOptions,
    } = option;
    const { inputValue } = selectProps;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';
    const optionLabel = optionNameHtml && !optionNameHtml.includes('<script') ? optionNameHtml : optionName || label;

    const matchIndex = optionLabel.toLowerCase().indexOf(inputValue.toLowerCase());
    let matchedString = '';

    if (matchIndex > -1) {
        matchedString = optionLabel.substring(matchIndex, matchIndex + inputValue.length);
    }
    const optionLabelWithSearch = optionLabel.replace(new RegExp(inputValue, 'i'), '<span class="matched">' + matchedString + '</span>');

    const getPimInfo = useCallback(() => {
        if (!getPimInfoModalOptions) return;
        setModal(getPimInfoModalOptions(option));
    }, [option]);

    return (
        <Wrapper
            className={cx(['select-option', { _selected: isSelected }])}
            isSelected={isSelected}
            isFocused={isFocused}
            ref={innerRef}
            {...innerProps}
        >
            <div className="select-label">
                <span className="select-name" dangerouslySetInnerHTML={{ __html: optionLabelWithSearch }} />
                {articul && showPimParams ? (
                    <Button
                        type="button"
                        styleType="icon-only"
                        className="select-button _pim-info"
                        onClick={e => {
                            e.stopPropagation();
                            getPimInfo();
                        }}
                        tip="Подробнее о комплектующей"
                    >
                        <div className="icon" />
                    </Button>
                ) : (
                    ''
                )}
                <ConfiguratorFormTags className="select" product={option} showAmountInStock={showAmountInStock ?? false} />
            </div>

            {!noPrice && (
                <div className="select-price">
                    <div className="select-price__dash">&mdash;</div>
                    <div className={cx(['select-price__price', { _error: !optionPrice }])}>
                        {price} {!!optionPrice && ' $'}
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default memo(Option);
