import cx from 'classnames';
import React from 'react';
import { IConfigurationFormProduct } from '../../assets/ts/types';
import { ONLY_FOR_PRESALE_TAG_TEXT } from './assets';

interface IProps {
    className: string;
    product: IConfigurationFormProduct;
    showAmountInStock: boolean;
}

const ConfiguratorFormTags = ({ className, product, showAmountInStock }: IProps) => {
    const { onlyForPresale, tag, amountInStockText } = product;
    const inStockEmpty = amountInStockText?.toLowerCase().includes('нет на складе');
    const inStockSmall = amountInStockText?.toLowerCase().includes('мало');

    return showAmountInStock || onlyForPresale || tag ? (
        <div className={`${className}-tags`}>
            {showAmountInStock && (
                <div
                    className={cx([
                        `${className}-tag alert _amount-in-stock`,
                        {
                            'alert-success': !(inStockSmall || inStockEmpty),
                            'alert-warning': inStockSmall,
                            'alert-danger': inStockEmpty,
                        },
                    ])}
                >
                    {amountInStockText ?? 'hello'}
                </div>
            )}
            {onlyForPresale && <div className={`${className}-tag alert alert-info _presale`}>{ONLY_FOR_PRESALE_TAG_TEXT}</div>}
            {tag && <div className={`${className}-tag alert alert-info _label`}>{tag}</div>}
        </div>
    ) : (
        <></>
    );
};

export default ConfiguratorFormTags;
