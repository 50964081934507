import {
    IConfiguration,
    IConfigurationBase,
    IConfigurationDetail,
    IConfigurationDetailProduct,
    IConfigurationsCopy,
    IConfigurationsList,
    IDataConfiguration,
    IDataConfigurationBase,
    IDataConfigurationDetail,
    IDataConfigurationDetailProduct,
    IDataConfigurationsCopy,
    IDataConfigurationsResponse,
} from '../../types';
import { convertColumnsSettings } from './user-settings';

const convertConfigurationsBase = ({ is_draft, doc_sheet_name, tkp_id, ...rest }: IDataConfigurationBase) =>
    ({ ...rest, isDraft: is_draft ?? false, sheetName: doc_sheet_name, tkpId: tkp_id } as IConfigurationBase);

const convertConfigurationDetail = ({ items, ...rest }: IDataConfigurationDetail) => {
    const listConfDetailProducts: IConfigurationDetailProduct[] = items?.map((product: IDataConfigurationDetailProduct) => {
        const { pricelist_id, count, count_in_stock, ...rest } = product;
        return { ...rest, article: pricelist_id, amount: count, amountInStock: count_in_stock };
    });

    const configuration: IConfigurationDetail = {
        ...convertConfigurationsBase(rest),
        items: listConfDetailProducts,
    };

    return configuration;
};

const convertConfigurationsCopy = ({ copied_configuration_id }: IDataConfigurationsCopy) =>
    ({ copiedConfigurationId: copied_configuration_id } as IConfigurationsCopy);

const convertConfiguration = (configuration: IDataConfiguration) => {
    const {
        actions,
        app_id,
        client_name,
        created_at,
        count,
        erp_bill_number,
        erp_bill_status,
        finished_product_article,
        is_deleted,
        is_interval_price_refresh_on,
        is_virtual,
        naming_configuration_custom,
        naming_configuration_is_custom,
        partner_name,
        pending_event,
        price,
        show_details,
        tax_id,
        type_config_virtual,
        updated_at,
        use_compatibility,
        use_special_price,
        user,
        ...rest
    } = configuration;
    const { last_name, first_name, patronymic_name, ...restUser } = user;

    return {
        ...convertConfigurationsBase(rest),
        actions,
        amount: count,
        appId: app_id,
        autoPriceRefreshOn: is_interval_price_refresh_on,
        billNumber: erp_bill_number,
        clientName: client_name,
        creationDate: created_at,
        deleted: is_deleted ?? false,
        ERPUploadStatus: erp_bill_status,
        finishedProductArticle: finished_product_article,
        isVirtual: is_virtual ?? false,
        namingCustom: naming_configuration_custom,
        namingCustomUsed: naming_configuration_is_custom,
        partnerName: partner_name,
        pending: pending_event,
        price,
        showDetails: show_details ?? false,
        taxId: tax_id,
        typeConfigVirtual: type_config_virtual === 'C' ? null : type_config_virtual === 'V' ? 'В' : 'К',
        updateDate: updated_at,
        useCompatibility: use_compatibility ?? false,
        useSpecialPrice: use_special_price ?? false,
        user: {
            ...restUser,
            lastName: last_name,
            firstName: first_name,
            patronymic: patronymic_name,
        },
    } as IConfiguration;
};

const convertConfigurations = ({
    configurations,
    page,
    pages_total,
    count,
    prev,
    next,
    show_erp_bill,
    available_columns,
}: IDataConfigurationsResponse) =>
    ({
        configurations: configurations.map(configuration => convertConfiguration(configuration)),
        configurationsTotal: count,
        page,
        pagePrev: prev,
        pageNext: next,
        pagesTotal: pages_total,
        showERP: show_erp_bill,
        availableColumns: convertColumnsSettings(available_columns),
    } as IConfigurationsList);

export default convertConfigurations;
export { convertConfigurationsCopy, convertConfiguration, convertConfigurationDetail };
