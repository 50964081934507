import {
    IConfigurationForm,
    IConfigurationFormCategory,
    IConfigurationFormField,
    IConfigurationFormProduct,
    IConfigurationFormProductPimInfo,
    IConfigurationFormS4bProduct,
    IConfigurationFormSearchAttribute,
    IConfigurationFormSearchForm,
    IConfigurationFormSlot,
    IConfigurationFormSlotFilter,
    IDataConfigurationForm,
    IDataConfigurationFormCategory,
    IDataConfigurationFormField,
    IDataConfigurationFormProduct,
    IDataConfigurationFormProductPimInfo,
    IDataConfigurationFormS4bSearchProduct,
    IDataConfigurationFormSearchAttribute,
    IDataConfigurationFormSearchForm,
    IDataConfigurationFormSlot,
    IDataConfigurationFormSlotFilter,
} from '../../types';
import { convertValidation } from '../forms';

const convertSlotFilters = (filters: IDataConfigurationFormSlotFilter[] | null) =>
    filters
        ? filters.map(
              ({ validation, description_field, options, ...rest }) =>
                  ({
                      ...rest,
                      tip: description_field,
                      validation: convertValidation(validation),
                      options: validation.type === 'bool_list' ? ['Да', 'Нет'] : options,
                  } as IConfigurationFormSlotFilter)
          )
        : null;

const convertProduct = (product: IDataConfigurationFormProduct) => {
    const { pricelist_id, count, count_in_stock, count_in_stock_text, is_recommended, only_for_presale, show_pim_params, name_html, label, ...rest } =
        product;
    return {
        ...rest,
        nameHtml: name_html,
        articul: pricelist_id,
        amountInStock: count_in_stock ?? 0,
        amountInStockText: count_in_stock_text,
        amountRequired: count,
        recommended: is_recommended,
        onlyForPresale: only_for_presale,
        showPimParams: show_pim_params,
        tag: label,
    } as IConfigurationFormProduct;
};

const convertSlots = (slots: IDataConfigurationFormSlot[]) =>
    slots.map(slot => {
        const { name, selected_item, additional_items, ...rest } = slot;
        const [type, index] = name.split(':');

        return {
            name,
            type,
            index: Number(index),
            selectedItem: selected_item ? convertProduct(selected_item) : null,
            additionalItems: additional_items ? additional_items.map(ai => convertProduct(ai)) : null,
            amount: 1,
            ...rest,
        } as IConfigurationFormSlot;
    });

const convertCategories = (categories: IDataConfigurationFormCategory[]) =>
    categories.map(cat => {
        const {
            name,
            display_name,
            independent_slots,
            slots,
            available_items,
            available_amount,
            show_more_button,
            show_select_amount,
            show_count,
            show_count_additional_items,
            hide_price,
            hide_price_additional_items,
            is_disabled,
            search_available,
            search_regex,
            virtual_item_id,
            form_filters,
            ...rest
        } = cat;

        return {
            name,
            title: display_name,
            independentSlots: independent_slots,
            slots: convertSlots(slots),
            availableItems: available_items ? available_items.map(i => convertProduct(i)) : null,
            availableAmount: available_amount,
            showSelectAmount: show_select_amount,
            showButtonMore: show_more_button,
            showAmountInStock: show_count,
            showAmountInStockAdditionalItems: show_count_additional_items,
            hidePrice: hide_price,
            hidePriceAdditionalItems: hide_price_additional_items,
            disabled: is_disabled,
            searchAvailable: search_available,
            searchRegex: search_regex,
            virtualItemId: virtual_item_id,
            slotFilters: convertSlotFilters(form_filters),
            ...rest,
        } as IConfigurationFormCategory;
    });

const convertFields = (fields: IDataConfigurationFormField[] = []) =>
    fields.map(field => {
        const { validation, button, is_disabled, ...rest } = field;
        return {
            ...rest,
            validation: convertValidation(validation),
            disabled: is_disabled ?? false,
            button: button ? { actionName: button.action, actionTitle: button.button_text, allowEmpty: button.allow_empty } : null,
        } as IConfigurationFormField;
    });

const convertConfigurationForm = ({ categories, count, app_id, fields, parent_id, parent_naming, ...rest }: IDataConfigurationForm) =>
    ({
        ...rest,
        appId: app_id,
        amount: count,
        categories: convertCategories(categories),
        fields: convertFields(fields),
        parentId: parent_id,
        parentNaming: parent_naming,
    } as IConfigurationForm);

const convertConfigurationFormSearchAttributes = (searchAttributes: IDataConfigurationFormSearchAttribute[]) =>
    searchAttributes.map(
        ({ display_name, is_required, ...rest }) => ({ title: display_name, required: is_required, ...rest } as IConfigurationFormSearchAttribute)
    );

const convertConfigurationFormSearchForm = ({ available_action, attributes, items, virtual_item_price }: IDataConfigurationFormSearchForm) =>
    ({
        actionAvailable: available_action,
        attributes: convertConfigurationFormSearchAttributes(attributes),
        items: (items ?? []).map(i => convertProduct(i)),
        virtualItemPrice: virtual_item_price,
    } as IConfigurationFormSearchForm);

const convertConfigurationFormS4bProduct = ({
    part_number,
    in_stock,
    in_stock_available,
    in_transit,
    price_rub,
    price_usd,
    ...rest
}: IDataConfigurationFormS4bSearchProduct) =>
    ({
        ...rest,
        partNumber: part_number,
        inStock: in_stock,
        inStockAvailable: in_stock_available,
        inTransit: in_transit,
        priceRub: price_rub,
        priceUsd: price_usd,
    } as IConfigurationFormS4bProduct);

const convertConfigurationFormProductPimInfo = ({ category_title, photo_url, values, ...rest }: IDataConfigurationFormProductPimInfo) =>
    ({
        ...rest,
        categoryTitle: category_title,
        photoUrl: photo_url || null,
        attributes: { ...values },
    } as IConfigurationFormProductPimInfo);

export default convertConfigurationForm;
export {
    convertCategories,
    convertSlots,
    convertProduct,
    convertFields,
    convertConfigurationFormSearchForm,
    convertConfigurationFormSearchAttributes,
    convertConfigurationFormProductPimInfo,
    convertSlotFilters,
    convertConfigurationFormS4bProduct,
};
